import { IExtensivSnackbarProps } from "@extensiv/shared-reactcomponents"

export enum NotificationType {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  WARN = 'warning'
}

export interface INotificationMessage {
  type: NotificationType,
  msg: string,
  option?: Partial<IExtensivSnackbarProps>
}
